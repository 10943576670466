import React from "react"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import "./treatmentOptions.scss"

import TheaterVideo from "../components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import CTA from "../components/CTA/CTA"
import SEO from "../components/seo"
import { Link } from "gatsby"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"

function TreatmentBlocks(props) {
  let issues = [
    "Facial or dental infection",
    "Trauma to the head, neck, mouth, or face",
    "Urgent dental care to relieve severe pain",
    "Painful or symptomatic wisdom teeth",
    "Post-operative complications",
    "Tooth infection",
    "Tooth fracture ",
    "Defective restorations causing pain or swelling ",
    "Bleeding in the mouth that will not stop"
  ]

  var rows = []

  for (let i = 0; i < issues.length - 1; i += 3) {
    let row = (
      <>
        <div className="column covid-grid-block">
          <p>{issues[i]}</p>
        </div>

        <div className="column covid-grid-block">
          <p>{issues[i + 1]}</p>
        </div>

        <div className="column covid-grid-block">
          <p>{issues[i + 2]}</p>
        </div>
      </>
    )
    rows.push(row)
  }

  return rows
}

const TreatmentOptions = props => {
  return (
    <SharedStateProvider>
      <Layout>
        <SEO
          title="Online Video Consultations at Oral Surgery & Dental Implant Specialists South Carolina"
          description="We are offering online consultations in Oklahoma City, OK, during the COVID-19 outbreak."
        />
        <div className="joshua-tree-content treatment-options">
          <div className="columns top-section white-back">
            <div className="column is-4-tablet is-5-desktop"></div>
            <div className="column">
              <h1>Online Consultations</h1>
            </div>
          </div>
          <div className="columns open-online">
            <div className="column is-4-tablet is-5-desktop"></div>
            <div className="column">
              <h5>We’re Open for Online Consultations</h5>
              <p className="large">
                In order to not overwhelm emergency hospital systems and to help
                maintain social distancing as recommended by the Centers for
                Disease Control (CDC), Oral Surgery & Dental Implant Specialists
                South Carolina is offering online consultations. During these
                consultations, one of our surgeons can assess your area of
                concern and determine the right course of treatment. Read our
                COVID-19 information <Link to="/covid-19/">here</Link>.
              </p>

              <div className="covid-button-grid button-blocks">
                <div style={{ gridColumn: "span 2" }}>
                  <p className="small">
                    Contact our office if you have any&nbsp;questions.
                  </p>
                  <a
                    href="tel:+18438495188"
                    className="standard-button covid-button-text standalone"
                  >
                    (843) 849-5188
                  </a>
                </div>
              </div>
            </div>
            <div className="column is-4-tablet is-5-desktop"></div>
          </div>

          <div className="columns">
            <div className="column is-6"></div>
            <div className="column">
              <div className="emergency-issues">
                <h5>Emergency Issues</h5>
                <p>
                  If you’re experiencing any of the following conditions,
                  schedule an online consultation:
                </p>
              </div>
              <div className="treatment-block-grid">
                <TreatmentBlocks />
              </div>
            </div>
            <div className="column is-6"></div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default TreatmentOptions
